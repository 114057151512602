


































































































































import {ApplicationModule} from '@/store/modules/application';
import {Vue, Component} from 'vue-property-decorator';
import VueSignature from '@/components/User/vue-signature.vue';
import {AcompteModule} from '@/store/modules/acompte';
import {CreateAcompteRequest} from '@/models/viewmodels/acompte/CreateAcompteRequest';
import {padNumber} from '@/utils';

@Component({
    components: {
        VueSignature,
    },
})
export default class NewAcompteModal extends Vue {
    public showDialog = false;
    public isMonth13 = false;
    public isMultiple = false;
    public comment = '';
    public step = 1;

    public name = '';
    public amount: string | number = 0;
    public fieldAmountError = '';
    public mois = '';
    public fieldMonthError = '';
    public otherError = '';
    public amountOptions = {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        delimiter: ' ',
        rawValueTrimPrefix: true,
    };

    public choixMois: string[] = [];

    private mounted() {
        this.$nextTick(() => {
            this.initNewAcompte();
        });
    }

    public get loading() {
        return ApplicationModule.loading;
    }

    private initNewAcompte() {
        this.fieldAmountError = '';
        this.fieldMonthError = '';
        this.otherError = '';

        const today = new Date();
        let mth = today.getMonth();
        let year = today.getFullYear();

        this.choixMois = [];
        for (let i = 0; i < 12; i++) {
            switch (mth) {
                case 0:
                    this.choixMois.push(`Janvier ${year}`);
                    break;
                case 1:
                    this.choixMois.push(`Février ${year}`);
                    break;
                case 2:
                    this.choixMois.push(`Mars ${year}`);
                    break;
                case 3:
                    this.choixMois.push(`Avril ${year}`);
                    break;
                case 4:
                    this.choixMois.push(`Mai ${year}`);
                    break;
                case 5:
                    this.choixMois.push(`Juin ${year}`);
                    break;
                case 6:
                    this.choixMois.push(`Juillet ${year}`);
                    break;
                case 7:
                    this.choixMois.push(`Août ${year}`);
                    break;
                case 8:
                    this.choixMois.push(`Septembre ${year}`);
                    break;
                case 9:
                    this.choixMois.push(`Octobre ${year}`);
                    break;
                case 10:
                    this.choixMois.push(`Novembre ${year}`);
                    break;
                case 11:
                    this.choixMois.push(`Décembre ${year}`);
                    break;
                default:
                    break;
            }
            mth++;
            if (mth === 12) {
                mth = 0;
                year++;
            }
        }

        // eslint-disable-next-line prefer-destructuring
        this.mois = this.choixMois[0];

        this.name = '';
        this.comment = '';
        this.isMonth13 = false;
        this.isMultiple = false;
        this.amount = 0;
        this.showDialog = false;
    }

    public nextStep2() {
        this.fieldAmountError = '';
        this.fieldMonthError = '';
        let hasErr = false;
        if (!this.amount) {
            this.fieldAmountError = "Le montant de l'acompte doit être supérieur à 0 €.";
            hasErr = true;
        }
        const finalAmount = +(this.amount as string).replace(/\s/g, '');
        if (finalAmount <= 0) {
            this.fieldAmountError = "Le montant de l'acompte doit être supérieur à 0 €.";
            hasErr = true;
        }
        if (
            (!this.isMonth13 || !this.isMultiple) &&
            !this.mois &&
            this.choixMois.indexOf(this.mois) < 0
        ) {
            this.fieldMonthError =
                'Veuiller sélectionner le mois duquel votre acompte doit être débité.';
        }
        if (hasErr) {
            return;
        }
        this.step = 2;
    }

    public postNewAcompte() {
        this.fieldAmountError = '';
        this.fieldMonthError = '';
        this.otherError = '';
        let hasErr = false;
        if (!this.amount) {
            this.fieldAmountError = "Le montant de l'acompte doit être supérieur à 0 €.";
            hasErr = true;
        }
        const finalAmount = +(this.amount as string).replace(/\s/g, '');
        if (finalAmount <= 0) {
            this.fieldAmountError = "Le montant de l'acompte doit être supérieur à 0 €.";
            hasErr = true;
        }
        if (
            (!this.isMonth13 || !this.isMultiple) &&
            !this.mois &&
            this.choixMois.indexOf(this.mois) < 0
        ) {
            this.fieldMonthError =
                'Veuiller sélectionner le mois duquel votre acompte doit être débité.';
        }
        if (hasErr) {
            this.step = 1;
            return;
        }

        const signPad = this.$refs.signPad as unknown as VueSignature;
        if (signPad.isEmpty) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Une demande d'acompte ne peut-être envoyée sans être signée.",
                timeout: 5 * 1000,
            });
            return;
        }

        const acompte = {
            name: this.name,
            montant: finalAmount,
            comment: this.comment,
        } as CreateAcompteRequest;

        if (this.isMonth13) {
            acompte.mois = 'Acompte sur 13ème mois';
            acompte.isMois13 = true;
        } else if (this.isMultiple) {
            acompte.mois = 'Acompte à déduire en plusieurs fois';
            acompte.isMultiple = true;
        } else {
            acompte.mois = this.mois;
        }

        const nowDateTime = new Date();

        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear(),
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes()),
        ].join(':')}`;
        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200, // stroke positionY, > default 40
        });
        acompte.signature = signPad.save('image/jpeg');

        // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
        const _self = this;

        AcompteModule.createAcompte(acompte)
            .then(() => {
                signPad.clear();
                this.initNewAcompte();
                this.showDialog = false;
            })
            .catch(() => {
                const errorMessage =
                    ((ApplicationModule.error as any)?.message as string | undefined) ?? 'UNKNOWN';
                signPad.clear();
                switch (errorMessage) {
                    case 'Vous devez renseigner un montant supérieur à 0 €.':
                        _self.fieldAmountError = errorMessage;
                        _self.amount = 0;
                        _self.step = 1;
                        break;
                    case 'Vous devez spécifier le mois sur lequel votre acompte sera débité.':
                        _self.fieldMonthError = errorMessage;
                        // eslint-disable-next-line prefer-destructuring
                        _self.mois = this.choixMois[0];
                        _self.isMonth13 = false;
                        _self.isMultiple = false;
                        _self.step = 1;
                        break;
                    default:
                        _self.otherError = errorMessage;
                        break;
                }
            });
    }
}
