import {Module, VuexModule, Mutation, Action, getModule} from 'vuex-module-decorators';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';
import {to} from '@/utils';
import {IAcompteData} from '@/entities/acompte/acompte.types';
import {CreateAcompteRequest} from '@/models/viewmodels/acompte/CreateAcompteRequest';
import {getAcomptes, postNewAcompte, updateAcompte} from '@/api/acompte';
import {Acompte} from '@/entities/acompte/acompte';
import {UpdateAcompteRequest} from '@/models/viewmodels/acompte/UpdateAcompteRequest';
import {ApplicationModule} from './application';
import {UserModule} from './user';

export interface IAcompteState {
    acomptes: Array<IAcompteData>;
    acompteId: string | null;
}

@Module({dynamic: true, store, name: 'acompte', namespaced: true})
class MAcompte extends VuexModule implements IAcompteState {
    public acomptes: Array<IAcompteData> = [];
    public acompteId: string | null = null;

    @Mutation
    private SET_ACOMPTES(acomptes: Array<IAcompteData>) {
        this.acomptes = acomptes;
    }

    @Mutation
    private SET_ACOMPTEID(id: string | null) {
        this.acompteId = id;
    }

    @Mutation
    private SET_ACOMPTE(acompte: IAcompteData) {
        const acomptes = cloneDeep(this.acomptes);
        const pIndex = acomptes.findIndex(ac => ac.id === acompte.id);
        if (pIndex !== -1) {
            acomptes.splice(pIndex, 1);
        }
        acomptes.push(acompte);
        this.acomptes = acomptes;
    }

    @Action
    public async createAcompte(data: CreateAcompteRequest) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        const [err, createResult] = await to(postNewAcompte(data));
        ApplicationModule.setLoading(false);
        if (err) {
            ApplicationModule.setError({message: "Une erreur inconnue s'est produite..."});
            throw new Error();
        }
        if (createResult) {
            if (createResult.succeeded && createResult.acompte?.id) {
                this.SET_ACOMPTE(createResult.acompte);
            } else if (
                createResult.errors &&
                Array.isArray(createResult.errors) &&
                createResult.errors.length > 0
            ) {
                ApplicationModule.setError({
                    message: createResult.errors[0]
                });
                throw new Error();
            }
        }
    }

    @Action
    public async updateAcompte(data: UpdateAcompteRequest) {
        if (UserModule.syncedHasAccess !== true) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: "Action impossible avec votre niveau d'accès.",
                timeout: 15 * 1000
            });
            return;
        }

        ApplicationModule.setLoading(true);
        const [err, updateResult] = await to(updateAcompte(data));
        ApplicationModule.setLoading(false);
        if (err) {
            ApplicationModule.setError({message: "Une erreur inconnue s'est produite..."});
            throw new Error();
        }
        if (updateResult) {
            if (updateResult.succeeded && updateResult.acompte?.id) {
                this.SET_ACOMPTE(updateResult.acompte);
            } else if (
                updateResult.errors &&
                Array.isArray(updateResult.errors) &&
                updateResult.errors.length > 0
            ) {
                ApplicationModule.setError({
                    message: updateResult.errors[0]
                });
                throw new Error();
            }
        }
    }

    @Action
    public async refreshListAcompte() {
        ApplicationModule.setLoading(true);
        const [, response] = await to(getAcomptes());
        ApplicationModule.setLoading(false);
        if (response && Array.isArray(response) && response.length > 0) {
            this.SET_ACOMPTES(response.map(ac => new Acompte(ac)));
        }
    }

    public get selected() {
        if (!this.acompteId) {
            return undefined;
        }

        const pIndex = this.acomptes.findIndex(ac => ac.id === this.acompteId);

        if (pIndex === -1) {
            return undefined;
        }

        return new Acompte(this.acomptes[pIndex]);
    }
}

export const AcompteModule = getModule(MAcompte);
