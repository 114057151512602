










import { Vue, Component } from "vue-property-decorator";
import SoonAvailableComponent from "@/components/Home/SoonAvailable.vue";

@Component({ components: { SoonAvailableComponent } })
export default class NotYetAvailableView extends Vue {}
