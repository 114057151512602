















import {Vue, Component, Prop} from 'vue-property-decorator';
import {IAcompteData} from '@/entities/acompte/acompte.types';
import {getAcompteApprovalColor} from '@/utils';

@Component({})
export default class AcompteListItem extends Vue {
    @Prop({type: Object, required: true})
    private item!: IAcompteData;

    private get approvalColor() {
        return getAcompteApprovalColor(this.etatAcompte);
    }

    private get etatAcompte() {
        return (this.$i18n.t('etatAcompte').valueOf() as string[])[this.item.etat];
    }

    private get montantAcompte() {
        return `${(this.item?.montant ?? 0).toFixed(2)} €`;
    }

    private handleClickDetail() {
        this.$emit('select', this.item.id);
    }
}
