import { reviveUTCDate } from '@/utils';
import { EtatAcompte, IAcompte, IAcompteData } from './acompte.types';

export class Acompte implements IAcompte {
    public id: string;
    public deductionCommentaires?: string;
    public etat: EtatAcompte;
    public mois: string;
    public montant: number;
    public motifAnnulation?: string;
    public motifRefus?: string;
    public name: string;
    public version: number;
    public createdDate: Date;

    constructor(data: IAcompteData) {
        this.id = data.id;
        this.deductionCommentaires = data.deductionCommentaires;
        this.etat = data.etat;
        this.mois = data.mois;
        this.montant = data.montant ?? 0;
        this.motifAnnulation = data.motifAnnulation;
        this.motifRefus = data.motifRefus;
        this.name = data.name;
        this.version = data.version;
        this.createdDate = data.createdDate ? reviveUTCDate(data.createdDate) : new Date();
    }
}
