import request from '@/utils/request';
import {CreateAcompteRequest} from '@/models/viewmodels/acompte/CreateAcompteRequest';
import {IAcompteData} from '@/entities/acompte/acompte.types';
import { CreateAcompteResult } from '@/models/viewmodels/acompte/CreateAcompteResult';
import { UpdateAcompteResult } from '@/models/viewmodels/acompte/UpdateAcompteResult';
import { UpdateAcompteRequest } from '@/models/viewmodels/acompte/UpdateAcompteRequest';

export const getAcomptes = (): Promise<Array<IAcompteData>> =>
    (request({
        url: '/Acompte/MesDonnees',
        method: 'get'
    }) as unknown) as Promise<Array<IAcompteData>>;

export const postNewAcompte = (acompte: CreateAcompteRequest): Promise<CreateAcompteResult> =>
    (request({
        url: '/Acompte/Create',
        method: 'post',
        data: acompte
    }) as unknown) as Promise<CreateAcompteResult>;

export const updateAcompte = (data: UpdateAcompteRequest): Promise<UpdateAcompteResult> =>
    (request({
        url: '/Acompte/Update',
        method: 'post',
        data
    }) as unknown) as Promise<UpdateAcompteResult>;
