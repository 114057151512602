/* eslint-disable-next-line no-shadow */
export enum EtatAcompte {
    DemandeInit = 0,
    Verification,
    Traitement,
    Verse,
    DeduitSalaire,
    Annule,
    Refuse
}

export interface IAcompteData {
    id: string;
    deductionCommentaires?: string;
    etat: EtatAcompte;
    mois: string;
    montant: number;
    motifAnnulation?: string;
    motifRefus?: string;
    name: string;
    version: number;
    createdDate?: string | Date;
}

export interface IAcompte extends IAcompteData {
    createdDate: Date;
}
