



























































































































































































































































import {ApplicationModule} from '@/store/modules/application';
import {Vue, Component, PropSync} from 'vue-property-decorator';
import {AcompteModule} from '@/store/modules/acompte';
import {EtatAcompte, IAcompteData} from '@/entities/acompte/acompte.types';
import {getAcompteApprovalColor, padNumber, formatDate} from '@/utils';
import {
    UpdateAcompteRequest,
    UpdateAcompteRequestMode,
} from '@/models/viewmodels/acompte/UpdateAcompteRequest';
import VueSignature from '@/components/User/vue-signature.vue';

@Component({
    components: {
        VueSignature,
    },
})
export default class AcompteCard extends Vue {
    @PropSync('showDialog') private syncedShowDialog!: boolean;
    public editMode = 0;
    public newName = '';
    public fieldNameError = '';
    public newAmount = 0;
    public fieldAmountError = '';
    public newMonth = '';
    public isMonth13 = false;
    public isMultiple = false;
    public fieldMonthError = '';
    public newComment = '';
    public fieldCommentError = '';
    public otherError = '';
    public newCancelComment = '';
    public fieldCancelError = '';
    public amountOptions = {
        numeral: true, numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        delimiter: ' ',
        rawValueTrimPrefix: true
    };

    public get selected() {
        return AcompteModule.selected;
    }

    public get name() {
        return this.selected?.name ?? '';
    }

    public get amount() {
        return this.selected?.montant ?? 0;
    }

    public get month() {
        return this.selected?.mois ?? '';
    }

    public get state() {
        return (this.$i18n.t('etatAcompte').valueOf() as string[])[this.selected?.etat ?? 0];
    }

    public get computedIsRefused() {
        return this.selected?.etat === EtatAcompte.Refuse;
    }

    public get motifRefus() {
        return this.selected?.motifRefus ?? 'Aucun motif.';
    }

    public get computedIsCancelled() {
        return this.selected?.etat === EtatAcompte.Annule;
    }

    public get motifAnnulation() {
        return this.selected?.motifAnnulation ?? 'Aucun motif.';
    }

    public get computedShowExtraComments() {
        return this.selected?.etat === EtatAcompte.DeduitSalaire;
    }

    public get deductionCommentaires() {
        return this.selected?.deductionCommentaires ?? 'Aucune remarque particulière.';
    }

    public get createdDate() {
        return this.selected?.createdDate ?? new Date();
    }

    public get formatedCreatedDate() {
        return `Le ${formatDate(this.createdDate.toISOString().substr(0, 10))}`;
    }
    
    public choixMois: string[] = [];

    public get loading() {
        return ApplicationModule.loading;
    }

    public get computedIsEditDisabled() {
        return this.selected?.etat !== EtatAcompte.DemandeInit;
    }

    public get computedModalTitle() {
        return this.editMode ? 'Modifier la demande' : 'Détails de la demande';
    }

    private get computedApprovalColor() {
        return getAcompteApprovalColor(this.state);
    }
    
    public handleEditButton() {
        this.newName = this.name;
        this.newAmount = this.amount;
        this.isMonth13 = this.month === 'Acompte sur 13ème mois';
        this.isMultiple = this.month === 'Acompte à déduire en plusieurs fois';
        this.newMonth = (this.isMonth13 || this.isMultiple) ? '' : this.month;
        this.editMode = 1;

        let mth = this.createdDate.getMonth();
        let year = this.createdDate.getFullYear();

        this.choixMois = [];
        for (let i = 0; i < 12; i++) {
            switch (mth) {
                case 0:
                    this.choixMois.push(`Janvier ${year}`);
                    break;
                case 1:
                    this.choixMois.push(`Février ${year}`);
                    break;
                case 2:
                    this.choixMois.push(`Mars ${year}`);
                    break;
                case 3:
                    this.choixMois.push(`Avril ${year}`);
                    break;
                case 4:
                    this.choixMois.push(`Mai ${year}`);
                    break;
                case 5:
                    this.choixMois.push(`Juin ${year}`);
                    break;
                case 6:
                    this.choixMois.push(`Juillet ${year}`);
                    break;
                case 7:
                    this.choixMois.push(`Août ${year}`);
                    break;
                case 8:
                    this.choixMois.push(`Septembre ${year}`);
                    break;
                case 9:
                    this.choixMois.push(`Octobre ${year}`);
                    break;
                case 10:
                    this.choixMois.push(`Novembre ${year}`);
                    break;
                case 11:
                    this.choixMois.push(`Décembre ${year}`);
                    break;
                default:
                    break;
            }
            mth++;
            if (mth === 12) {
                mth = 0;
                year++;
            }
        }
    }

    public handleCancelButton() {
        this.editMode = 2;
    }

    public postUpdateAcompte() {
        this.fieldNameError = '';
        this.fieldAmountError = '';
        this.fieldMonthError = '';
        this.fieldCommentError = ''
        this.otherError = '';
        this.fieldCancelError = '';
        let hasErr = false;

        const signPad = (this.$refs.signPad as unknown) as VueSignature;
        if (signPad.isEmpty) {
            ApplicationModule.showSnackbar({
                displayed: true,
                color: 'error',
                text: `Une demande d'acompte ne peut-être ${(this.editMode === 1 ? 'modifiée' : 'annulée')} sans être signée.`,
                timeout: 5 * 1000,
            });
            return;
        }

        const acompteUpdate = {} as UpdateAcompteRequest;

        const nowDateTime = new Date();

        const dformat = `${[
            padNumber(nowDateTime.getDate()),
            padNumber(nowDateTime.getMonth() + 1),
            nowDateTime.getFullYear(),
        ].join('/')} à ${[
            padNumber(nowDateTime.getHours()),
            padNumber(nowDateTime.getMinutes()),
        ].join(':')}`;
        signPad.addWaterMark({
            text: `Signé le ${dformat}`,
            font: '15px Arial', // mark font, > default '20px sans-serif'
            style: 'all', // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
            fillStyle: 'red', // fillcolor, > default '#333'
            strokeStyle: 'blue', // strokecolor, > default '#333'
            x: 50, // fill positionX, > default 20
            y: 200, // fill positionY, > default 20
            sx: 50, // stroke positionX, > default 40
            sy: 200, // stroke positionY, > default 40
        });
        acompteUpdate.signature = signPad.save('image/jpeg');

        if (this.editMode === 1) {
            if (!this.newName) {
                this.fieldNameError =
                    "Veuiller saisir un nom pour que votre demande d'acompte soit valide.";
                hasErr = true;
            }
            if (!this.newAmount || +this.newAmount <= 0) {
                this.fieldAmountError = "Le montant de l'acompte doit être supérieur à 0 €.";
                hasErr = true;
            }
            if (
                (!this.isMonth13 || !this.isMultiple) &&
                !this.newMonth &&
                this.choixMois.indexOf(this.newMonth) < 0
            ) {
                this.fieldMonthError =
                    'Veuiller sélectionner le mois duquel votre acompte doit être débité.';
            }
            acompteUpdate.mode = UpdateAcompteRequestMode.Edit;
            acompteUpdate.acompte = {
                ...this.selected,
                name: this.newName,
                montant: this.newAmount,
            } as IAcompteData;

            if (this.isMonth13) {
                acompteUpdate.acompte.mois = 'Acompte sur 13ème mois';
            } else if (this.isMultiple) {
                acompteUpdate.acompte.mois = 'Acompte à déduire en plusieurs fois';
            } else {
                acompteUpdate.acompte.mois = this.newMonth;
            }
        } else if (this.editMode === 2) {
            if (!this.newCancelComment) {
                this.fieldCancelError = "Veuiller saisir le motif de l'annulation.";
                hasErr = true;
            }
            acompteUpdate.mode = UpdateAcompteRequestMode.Cancel;
            acompteUpdate.acompte = {
                ...this.selected,
                etat: EtatAcompte.Annule,
                motifAnnulation: this.newCancelComment,
            } as IAcompteData;
        }

        if (hasErr) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-this-alias,no-underscore-dangle
        const _self = this;

        AcompteModule.updateAcompte(acompteUpdate)
            .then(() => {
                this.newName = '';
                this.isMonth13 = false;
                this.isMultiple = false;
                this.newAmount = 0;
                this.newMonth = '';
                this.newComment = '';
                this.syncedShowDialog = false;
                this.newCancelComment = '';
                this.editMode = 0;
            })
            .catch(() => {
                const errorMessage =
                    ((ApplicationModule.error as any)?.message as string | undefined) ?? 'UNKNOWN';
                switch (errorMessage) {
                    case 'Vous devez renseigner un nom valide.':
                        _self.fieldNameError = errorMessage;
                        _self.newName = _self.name;
                        break;
                    case 'Vous devez renseigner un montant supérieur à 0 €.':
                        _self.fieldAmountError = errorMessage;
                        _self.newAmount = _self.amount;
                        break;
                    case 'Vous devez spécifier le mois sur lequel votre acompte sera débité.':
                        _self.fieldMonthError = errorMessage;
                        _self.isMonth13 = _self.month === 'Acompte sur 13ème mois';
                        _self.isMultiple = _self.month === 'Acompte à déduire en plusieurs fois';
                        _self.newMonth = (_self.isMonth13 || _self.isMultiple) ? '' : _self.month;
                        break;
                    case "Vous devez saisir le motif de l'annulation.":
                        _self.fieldCancelError = errorMessage;
                        _self.newCancelComment = '';
                        break;
                    default:
                        _self.otherError = errorMessage;
                        break;
                }
            });
    }
}
