import { IAcompteData } from '@/entities/acompte/acompte.types';

/* eslint-disable-next-line no-shadow */
export enum UpdateAcompteRequestMode {
    Edit,
    Cancel
}

export interface UpdateAcompteRequest {
    mode: UpdateAcompteRequestMode;
    acompte: IAcompteData;
    signature: string;
}