

















































import {Vue, Component} from 'vue-property-decorator';
import AcompteCardComponent from '@/components/Acompte/AcompteCard.vue';
import NewAcompteComponent from '@/components/Acompte/NewAcompte.vue';
import AcompteListItem from '@/components/Acompte/AcompteListItem.vue';
import AideAcompte from '@/components/Aide/AideAcompte.vue';
import IllustrationLakeMountainComponent from '@/components/Illustrations/LakeMountain.vue';
import {AcompteModule} from '@/store/modules/acompte';
import {IAcompteData} from '@/entities/acompte/acompte.types';

@Component({
    components: {
        AcompteCardComponent,
        NewAcompteComponent,
        AcompteListItem,
        AideAcompte,
        IllustrationLakeMountainComponent
    },
})
export default class AbsencesView extends Vue {
    private dialogAddOpened = false;
    private bShowDetailsDialog = false;
    public displayHelp = false;

    private created() {
        AcompteModule.refreshListAcompte();
    }

    public get computedNothingToDisplay() {
        return !this.listedesAcomptes || this.listedesAcomptes.length === 0;
    }

    get listedesAcomptes(): Array<IAcompteData> {
        return AcompteModule.acomptes;
    }

    public handleSelectionChanged(id: string): void {
        this.$store.commit('acompte/SET_ACOMPTEID', id);
        this.bShowDetailsDialog = true;
    }
}
